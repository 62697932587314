import 'swiper/css/swiper.css';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import LoadingBar from 'react-redux-loading-bar';
import { Router } from 'react-router-dom';
import Preloader from './components/ui/routine/Preloader';
import { $history } from './helpers';
import RootRouter from './routes/RootRouter';
import * as serviceWorker from './serviceWorker';
import { store } from './store/configureStore';

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Preloader />}>
      <Router history={$history}>
        <LoadingBar
          style={{ backgroundColor: '#ffffff', height: '4px', opacity: '1', zIndex: '100000', position: 'fixed' }}
          updateTime={100}
          maxProgress={85}
        />
        <RootRouter />
      </Router>
    </Suspense>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
