import React, { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { PAGE } from '../routes/client';

const Market = lazy(() => import('../components/pages/market'));

const Profile = lazy(() => import('../components/pages/profile'));

export const AuthorizedRoutes = () => {
  return (
    <Switch>
      <Route path={PAGE.MARKET} component={Market} />
      <Route path={PAGE.PROFILE} component={Profile} />
      <Redirect to={PAGE.MARKET} />
    </Switch>
  );
};
