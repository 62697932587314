import { loadingBarReducer } from 'react-redux-loading-bar';
import { combineReducers } from 'redux';
import { authReducer } from '../containers/auth/reducer';
import { homeReducer } from '../containers/home/reducer';
import { homeownerReducer } from '../containers/homeowner/reducer';
import { investorReducer } from '../containers/investor/reducer';
import { marketReducer } from '../containers/market/reducer';

export const rootReducer = combineReducers({
  loadingBar: loadingBarReducer,
  auth: authReducer,
  home: homeReducer,
  market: marketReducer,
  homeowner: homeownerReducer,
  investor: investorReducer,
});
