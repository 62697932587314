import React from 'react';
import { useSelector } from 'react-redux';
import { AuthorizedRoutes } from './AutorizedRoutes';
import { GuestRoutes } from './GuestRoutes';

const RootRouter = () => {
  const isLoged = useSelector((state) => state.auth.session);

  return <>{isLoged ? <AuthorizedRoutes /> : <GuestRoutes />}</>;
};

export default RootRouter;
