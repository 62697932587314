import types from './types';

const initialState = {
  landing: null,
};

export function homeReducer(state = initialState, action) {
  switch (action.type) {
    case types.LANDING:
      return { ...state, landing: action.landing };

    default:
      return state;
  }
}
