import types from './types';

const initialState = {
  compactPanel: false,
  compactMap: false,
  layoutCard: false,
  openFilter: false,
  typeCard: false,
  typeCardGraph: 'image',
  filter: {},
  dataMap: {},
  compare: false,
  compares: [],
  compactFilter: false,
};

export function marketReducer(state = initialState, action) {
  switch (action.type) {
    case types.COMPACT_PANEL:
      return { ...state, compactPanel: action.compactPanel };

    case types.COMPACT_MAP:
      return { ...state, compactMap: action.compactMap };

    case types.LAYOUT_CARD:
      return { ...state, layoutCard: action.layoutCard };

    case types.FILTER:
      return { ...state, filter: action.filter };

    case types.OPEN_FILTER:
      return { ...state, openFilter: action.openFilter };

    case types.DATA_MAP:
      return { ...state, dataMap: action.dataMap };

    case types.TYPE_CARD:
      return { ...state, typeCard: action.typeCard };

    case types.TYPE_CARD_GRAPH:
      return { ...state, typeCardGraph: action.typeCardGraph };

    case types.COMPARE:
      return { ...state, compare: action.compare };

    case types.COMPARES:
      return { ...state, compares: action.compares };

    case types.COMPACT_FILTER:
      return { ...state, compactFilter: action.compactFilter };
    case types.SEARCH:
      return { ...state, dataMap: action.dataMap };

    default:
      return state;
  }
}
