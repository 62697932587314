import React from 'react';
import styled from 'styled-components';
import logo from '../../../assets/images/shared/logo.svg';
import { DEVICE } from '../../../helpers';

const PreloaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${DEVICE.COLOR.LAYOUT};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PreloaderSpinner = styled.div`
  img {
    width: 149px;
    max-height: 49px;
    display: block;
  }
`;

const Preloader = () => {
  return (
    <PreloaderWrapper>
      <PreloaderSpinner>
        <img src={logo} alt="propertiva" />
      </PreloaderSpinner>
    </PreloaderWrapper>
  );
};

export default Preloader;
