import types from './types';

const initialState = {
  investorPortfolio: {},
};

export function investorReducer(state = initialState, action) {
  switch (action.type) {
    case types.INVESTOR_PORTFOLIO:
      return { ...state, investorPortfolio: action.investorPortfolio };
    default:
      return state;
  }
}
