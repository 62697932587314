export const PAGE = {
  HOME: '/home',
  AUTH: '/auth',
  MARKET: '/market',
  PROFILE: '/profile',
  ERROR: '/404',
  ANY: '*',
};

export const AUTH = {
  SIGN_IN: `${PAGE.AUTH}/sign-in`,
  SIGN_UP: `${PAGE.AUTH}/sign-up`,
  VERIFY: `${PAGE.AUTH}/sign-up/verify`,
  CODE: `${PAGE.AUTH}/sign-up/code`,
  WELCOME: `${PAGE.AUTH}/sign-up/welcome`,
  COMPLETE: `${PAGE.AUTH}/complete`,
  FORGOT_PASSWORD: `${PAGE.AUTH}/forgot-password`,
};

export const HOME = {
  LANDING: `${PAGE.HOME}`,
  HOW_WE_WORK: `${PAGE.HOME}/how-we-work`,
  HOW_OWNER: `${PAGE.HOME}/how-owner`,
  INVESTOR: `${PAGE.HOME}/investor`,
  GUEST_PROP_DETAILS: `${PAGE.HOME}/prop-details`,
  DISCUSSION_BOARD: `${PAGE.HOME}/discussion`,
};

export const MARKET = {
  HOW_OWNER: `${PAGE.MARKET}/how-owner`,
  MY_PROPERTIES: `${PAGE.MARKET}/how-owner/my-properties`,
  PROPERTY_DETAILS: `${PAGE.MARKET}/how-owner/property-details`,
  ORDER_HISTORY: `${PAGE.MARKET}/how-owner/order-history`,
  UPLOAD_PROPERTY: `${PAGE.MARKET}/how-owner/upload-property`,
  UPLOAD_PROPERTY_PROGRESS: `${PAGE.MARKET}/how-owner/upload-property-progress`,
  LIST_HOMEOWNER: `${PAGE.MARKET}/how-owner/list-homeowner`,
  INVESTOR: `${PAGE.MARKET}/investor`,
  VIEWMARKET: `${PAGE.MARKET}/viewmarket`,
  WATCHLIST: `${PAGE.MARKET}/watchlist`,
};

export const INVESTOR = {
  MY_PORTFOLIO: `${MARKET.INVESTOR}/my-portfolio`,
  PROPERTY_DETAILS: `${MARKET.INVESTOR}/property-details`,
  ORDER_HISTORY: `${MARKET.INVESTOR}/order-history`,
  BUY_FUNCTION: `${MARKET.INVESTOR}/buy-function`,
};
