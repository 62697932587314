export const DEVICE = {
  COLOR: {
    BRAND: '#0e2667',
    TITLE: '#19488A',
    ACCENT: '#1971be',
    ACTION: '#1E89E3',
    OUTLINE: '#52A6D3',
    BLOCK: '#F5F9FC',
    FOOT: '#ECF4FD',
    DARK: '#959595',
    TEXT: '#2C2E32',
    DANGER: '#dc0000',
    BELL: '#C10021',
    MAINER: '#f2f2f2',
    LAYOUT: 'linear-gradient(90deg, #0e2667 0%, #1971be 100%)',
    LAYOUT_REVERSE: 'linear-gradient(90deg, #1971be 0%, #0e2667 100%)',
  },
  SIZE: {
    DESKTOP: 1280,
    NOTEBOOK: 1200,
    TABLET: 992,
    MOBILE: 768,
  },
  SHADOW: '0 3px 10px rgba(13, 35, 155, .13)',
  SHADOW_OUTLINE: '0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12)',
  OFFSET: 15,
  MOVE: 'all .3s ease',
};
