export default {
  COMPACT_PANEL: 'COMPACT_PANEL',
  COMPACT_MAP: 'COMPACT_MAP',
  LAYOUT_CARD: 'LAYOUT_CARD',
  FILTER: 'FILTER',
  OPEN_FILTER: 'OPEN_FILTER',
  DATA_MAP: 'DATA_MAP',
  TYPE_CARD: 'TYPE_CARD',
  TYPE_CARD_GRAPH: 'TYPE_CARD_GRAPH',
  COMPARE: 'COMPARE',
  COMPARES: 'COMPARES',
  COMPARES_CLONE: 'COMPARES_CLONE',
  COMPACT_FILTER: 'COMPACT_FILTER',
  SEARCH: 'SEARCH',
};
