import types from './types';

const initialState = {
  homeownerData: {},
  homeownerCurrentPropertyDetails: {},
  currentProperty: {},
  orderHistory: {},
  graphData: {},
};

export function homeownerReducer(state = initialState, action) {
  switch (action.type) {
    case types.HOMEOWNER_DATA:
      return { ...state, homeownerData: action.homeownerData };
    case types.HOMEOWNER_CURRENT_PROPERTY:
      return { ...state, currentProperty: action.propertyItem };
    case types.HOMEOWNER_ORDER_HISTORY:
      return { ...state, orderHistory: action.orderHistory };
    case types.HOMEOWNER_PROPERTY_DETAILS:
      return { ...state, homeownerCurrentPropertyDetails: action.propertyDetails };
    case types.HOMEOWNER_GRAPH_DATA:
      return { ...state, graphData: action.graphData };
    default:
      return state;
  }
}
