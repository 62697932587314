import { CONFIG } from './config';
import { DEVICE } from './device';
import $history from './historyCreate';

const $doc = document;

const $win = window;

const $body = $doc.body;

const $root = $doc.getElementById('root');

export { $doc, $win, $body, $root, CONFIG, $history, DEVICE };
