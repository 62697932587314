import types from './types';

const initialState = {
  session: null,
  errorMessage: null,
  user: null,
};

export function authReducer(state = initialState, action) {
  switch (action.type) {
    case types.SESSION:
      return { ...state, session: action.session };

    case types.USER:
      return { ...state, user: action.user };

    case types.ERROR_MESSAGE:
      return { ...state, errorMessage: action.errorMessage };

    default:
      return state;
  }
}
